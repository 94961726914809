export const pixelsToCm = (pixels: number, dpi: number) => {
  return (pixels * 2.54) / dpi;
};

export const cmToPixels = (cm: number, dpi: number) => {
  return (cm * dpi) / 2.54;
};

export const pixelsToMm = (pixels: number, dpi: number) => {
  return (pixels * 25.4) / dpi;
};

export const mmToPixels = (mm: number, dpi: number) => {
  return (mm * dpi) / 25.4;
};

export const mmToCm = (mm: number) => {
  return mm / 10;
};

export const cmToMm = (cm: number) => {
  return cm * 10;
};

export const unitKeys = {
  pixels: 'pixels',
  mm: 'mm',
  cm: 'cm',
};

export const sizeUnits = [unitKeys.pixels, unitKeys.mm, unitKeys.cm];

export const getRoundValue = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const getRoundValueForMm = (num: number) => {
  // @ts-ignore
  return Math.round(parseFloat(num).toFixed(2) * 10) / 10;
};

export const getValueByUnit = (value: number, inputUnit: string | undefined, outputUnit: string | undefined, dpi: number = 96) => {
  if (inputUnit === unitKeys.pixels && outputUnit === unitKeys.cm) {
    return getRoundValue(pixelsToCm(value, dpi));
  }
  if (inputUnit === unitKeys.pixels && outputUnit === unitKeys.mm) {
    return getRoundValueForMm(pixelsToMm(value, dpi));
  }
  if (inputUnit === unitKeys.cm && outputUnit === unitKeys.pixels) {
    return getRoundValue(cmToPixels(value, dpi));
  }
  if (inputUnit === unitKeys.mm && outputUnit === unitKeys.pixels) {
    return getRoundValue(mmToPixels(value, dpi));
  }
  if (inputUnit === unitKeys.mm && outputUnit === unitKeys.cm) {
    return getRoundValue(mmToCm(value));
  }
  if (inputUnit === unitKeys.cm && outputUnit === unitKeys.mm) {
    return getRoundValue(cmToMm(value));
  }
  return getRoundValue(value);
};
